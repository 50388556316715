.btnRequest {
    background-color: #198d88 !important;
    color: white;
    border-radius: 2px;
    border: none;

}

.btnRequest:hover {
    background-color: #1c9e97;
    border: none;
}

.error{
    color: red;
    font-size: 11px;
    margin-left: 3px;
    margin-top: 2px;
}


.success{
    color: rgb(1, 190, 1);
    font-size: 12px;
    margin-left: 3px;
    margin-top: 2px;
    font-weight: 600;
}

.formInputError{
    outline: 1px solid red !important;
}

.kalavidhi{
    padding: 20px;
    background-color: rgb(221, 221, 221);
}

.sigPad{
    height: 200px;
    width: 100%;
}

.signatureArea{
    border: 0.2px solid rgb(214, 214, 214);
    background-color: rgb(247 247 247);
   
    width: 100%;
}


.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
    display: block;
    margin: auto;
  }

  .warningMessage{
    color: rgb(252, 177, 3);
    font-size: 12px;
    margin-top: 7px;
    font-weight: 600;
  }