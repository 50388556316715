.error{
    color: red;
    font-size: 11px;
    margin-left: 3px;
    margin-top: 2px;
}


.formInputError{
    border: 1px solid red !important;
}


.lineInput{
    border-top: 0px !important;
    border-left: 0px  !important;
    border-right: 0px  !important;
    display: inline;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 1px solid black;
    outline: none;
}

.lineInput:focus{
    border: none!important;
    outline: 0 none!important;
}

.cedarvilleCursiveRegular {
    font-family: "Cedarville Cursive", cursive;
    font-weight: 400;
    text-transform: capitalize;
    font-style: normal;
    font-size: x-large;
}


.warningMessage{
    color: rgb(252, 177, 3);
    font-size: 12px;
    margin-top: 7px;
    font-weight: 600;
  }