.formInputError{
    outline: 1px solid red !important;
}

.error{
    color: red;
    font-size: 11px;
    margin-left: 3px;
    margin-top: 2px;
}

.warningMessage{
    color: #3b86d1 !important;
    font-size: 12px;
   margin-top: 0;
   padding: 0;
    font-weight: 520;

  }