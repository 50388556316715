.profileCard {
    border: none;
    /* border-left: 3px solid #333955; */
    border-radius: 0;
    padding: 10px;
    background-color: white;
}

.profileCard p{
    text-transform: capitalize;
    font-size: medium;
}
.navStyles{
    border: 1px solid rgb(119, 119, 119);
}

.cropCard{
    margin-top: 20px;
    padding-top: 10px;
    
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-left: 8px solid rgb(158, 207, 158);
    /* border-top: 2px solid rgb(158, 207, 158); */
}
.cropCard p{
    font-size: 16px !important;
    font-style: italic;
}

.cropCard h5{
    font-style: italic;
}
.cropCard table{
    font-style: italic;
}