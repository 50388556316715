.css-13cymwt-control{
    height: 2.875rem;
    border-radius: 0px;
}

.css-t3ipsp-control{
    height: 2.875rem;
    border-radius: 0px;
}

input:focus, select:focus{
  box-shadow:   0 0 0 1px #2684FF !important;
}