.formInputError{
    outline: 1px solid red !important;
}

.error{
    color: red;
    font-size: 11px;
    margin-left: 3px;
    margin-top: 2px;
}
.warningMessage{
    color: rgb(252, 177, 3);
    font-size: 12px;
    margin-top: 7px;
    font-weight: 600;
  }