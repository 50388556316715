.error{
    color: red;
    font-size: 11px;
    margin-left: 3px;
    margin-top: 2px;
}


.formInputError{
    outline: 1px solid red !important;
}

.warningMessage{
    color: rgb(252, 177, 3);
    font-size: 12px;
    margin-top: 7px;
    font-weight: 550;
  }

.kalavidhi{
    padding: 20px;
    background-color: rgb(221, 221, 221);
}

.sigPad{
    height: 200px;
    width: 100%;
    background-color: rgb(221, 221, 221);
}


.cedarvilleCursiveRegular {
    font-family: "Cedarville Cursive", cursive;
    font-weight: 400;
    text-transform: capitalize;
    font-style: normal;
}
  